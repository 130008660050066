<template>
  <b-card
    v-if="Object.keys(blogEdit).length"
    class="blog-edit-wrapper"
  >
    <!-- media -->
    <b-media
      no-body
      vertical-align="center"
    >
      <b-media-aside class="mr-75">
        <b-avatar
          size="42"
          :src="
            blogEdit.User.profileImage
              ? `/uploads/profileimage/3/${blogEdit.User.profileImage}`
              : require('@/assets/images/avatars/avatar.jpeg')
          "
        />
      </b-media-aside>
      <b-media-body>
        <small>
          <h6 class="user-name mb-0">
            {{ blogEdit.User.userName }}
          </h6>
          <b-link class="text-body text-right">
            {{ convertGenName(blogEdit.User.GenType.genName) }}
          </b-link>
        </small>

        <small class="text-muted float-right">
          {{ blogEdit.createdAt.substring(0, 16) }}
        </small>
      </b-media-body>
    </b-media>
    <!--/ media -->

    <!-- form -->
    <b-form
      class="mt-2"
      @submit.prevent="onSubmit"
      @reset="onReset"
    >
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="제목"
            label-for="title"
            class="mb-2"
          >
            <b-form-input
              id="title"
              v-model="blogEdit.title"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="내용"
            label-for="contents"
            class="mb-2"
          >
            <quill-editor
              id="contents"
              v-model="blogEdit.contents"
              :options="editorOption"
              @change="onEditorChange"
              @blur="onEditorBlur($event)"
              @focus="onEditorFocus($event)"
              @ready="onEditorReady($event)"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            type="submit"
          >
            저장
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            type="reset"
          >
            취소
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="secondary"
            class="float-right"
            @click="$router.go(-1)"
          >
            목록
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
  import {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BMediaAside,
    BMediaBody,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BImg,
    BFormFile,
    BLink,
    BButton,
  } from 'bootstrap-vue';
  import hljs from 'highlight.js';
  import debounce from 'lodash/debounce';
  import { quillEditor } from 'vue-quill-editor';
  import Ripple from 'vue-ripple-directive';
  import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils';
  import { ref } from '@vue/composition-api';

  export default {
    components: {
      BCard,
      BMedia,
      BAvatar,
      BCardText,
      BMediaAside,
      BMediaBody,
      BForm,
      BLink,
      BImg,
      BRow,
      BCol,
      BButton,
      BFormGroup,
      BFormInput,
      BFormFile,
      quillEditor,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        blogEdit: {},
        menuid: this.$route.params.menuid,
        postid: this.$route.params.postid,
        editorOption: {
          debug: 'log',
          theme: 'snow',
          placeholder: '내용을 입력하세요.',
          modules: {
            toolbar: {
              container: [
                ['bold', 'italic', 'underline', 'strike'],
                ['blockquote'],
                [{ list: 'ordered' }, { list: 'bullet' }],
                [{ indent: '-1' }, { indent: '+1' }],
                [{ size: ['small', false, 'large', 'huge'] }],
                [{ color: [] }, { background: [] }],
                [{ align: [] }],
                ['link', 'image', 'video'],
              ],
            },
          },
          syntax: {
            highlight: text => hljs.highlightAuto(text).value,
          },
        },
      };
    },
    created() {
      this.getContent();
    },
    watch: {
      $route: function () {
        this.getContent();
      },
    },
    methods: {
      onEditorChange: debounce(function (value) {
        this.content = value.html;
      }, 466),
      onEditorBlur(editor) {
        console.log('editor blur!', editor);
      },
      onEditorFocus(editor) {
        console.log('editor focus!', editor);
      },
      onEditorReady(editor) {
        console.log('editor ready!', editor);
      },
      convertGenName(genName) {
        return typeof genName === 'number' ? `${genName}기` : genName;
      },
      async getContent() {
        // const menuid = this.$route.params.menuid;
        // const postid = this.$route.params.postid;

        this.$http
          .get(`/post/edit/${this.menuid}/${this.postid}`)
          .then(res => {
            console.log(res.data.post);
            this.blogEdit = res.data.post;
            this.postConfig = res.data.config;

            document.querySelector('.content-header-title').textContent =
              res.data.config.menuName;
          })
          .catch(e => console.log(e.message));
      },
      onSubmit(event) {
        event.preventDefault();

        // put 메서드
        this.$http
          .put(`/post/edit/${this.menuid}/${this.postid}`, this.blogEdit)
          .then(res => {
            // this.$router.replace({
            //   name: this.$route.name,
            //   params: {
            //     id: this.menuid,
            //   },
            // });
            this.$router.go(-1);
          });
      },
      onReset(event) {
        event.preventDefault();
        this.getContent();
      },
    },
    setup() {
      const refInputEl = ref(null);
      const refPreviewEl = ref(null);

      const { inputImageRenderer } = useInputImageRenderer(
        refInputEl,
        base64 => {
          refPreviewEl.value.src = base64;
        }
      );

      return {
        refInputEl,
        refPreviewEl,
        inputImageRenderer,
      };
    },
  };
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/quill.scss';
  @import '@core/scss/vue/pages/page-blog.scss';
</style>
